import { useScroll, useSpring } from 'framer-motion'

export const useSmoothScroll = () => {
  const scroll = useScroll()

  const resolvedScrollYProgress = scroll.scrollYProgress.get() === 1 ? 0 : scroll.scrollYProgress
  const scrollYProgress = useSpring(resolvedScrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  const scrollY = useSpring(scroll.scrollY, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  })

  return { ...scroll, scrollYProgress, scrollY }
}
