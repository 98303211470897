import React, { useEffect, useRef } from 'react'
import { HTMLMotionProps, motion } from 'framer-motion'

interface Props extends HTMLMotionProps<'video'> {
  urls: {
    mp4: string
    webm: string
  }
  playbackRate?: number
  style?: any
}

const BgVideo: React.FC<Props> = ({ urls, playbackRate, ...otherProps }) => {
  const { mp4, webm, style = {} } = urls

  const ref = useRef(null)

  useEffect(() => {
    if (ref.current) {
      ;(ref.current as any).playbackRate = playbackRate || 1
    }
  }, [playbackRate])

  return (
    <motion.video
      autoPlay
      loop={true}
      controls={false}
      muted={true}
      playsInline={true}
      ref={ref}
      style={{
        objectFit: 'cover',
        ...style,
      }}
      {...otherProps}
    >
      <source src={webm} type="video/webm" />
      <source src={mp4} type="video/mp4" />
    </motion.video>
  )
}

export default BgVideo
