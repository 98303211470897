import React, { ReactNode, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import '../styles/layout.css'
import Background from './Background'
import { motion } from 'framer-motion'
import { useIsOnBlog } from '../utils/location'
import { Helmet } from 'react-helmet'

const Head = () => {
  return (
    <Helmet>
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="apple-touch-icon" sizes="192x192" href="/apple-touch-icon.png" />
      {/* Add more favicon links as needed */}
    </Helmet>
  )
}

interface LayoutProps {
  children: ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const isOnBlog = useIsOnBlog()

  return (
    <>
      <Head />
      <Background onDoneLoading={() => setIsLoading(false)} />

      <div className={`${isLoading ? 'hidden' : 'block'}`}>
        <Header />
      </div>

      {!isLoading && (
        <motion.main>
          <div
            style={{
              height: isOnBlog ? 64 : 120,
              backgroundColor: isOnBlog ? 'transparent' : 'black',
              opacity: 0.55,
            }}
          />
          {children}
        </motion.main>
      )}

      <Footer />
    </>
  )
}

export default Layout
