import { useEffect, useState } from 'react'

const isBrowser = typeof window !== 'undefined'

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const checkIfMobile = () => {
      if (!isBrowser) return false
      return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || /Mobi|Android/i.test(navigator.userAgent)
    }

    setIsMobile(checkIfMobile)
  }, [])

  return isMobile
}

export default useIsMobile
