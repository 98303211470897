import React, { useCallback, useState } from 'react'
import { HTMLMotionProps } from 'framer-motion'
import { motion } from 'framer-motion'

interface Props extends HTMLMotionProps<'button'> {
  title: string
  to?: string
}

const TextButton: React.FC<Props> = (props: Props) => {
  const { title, className, to, ...otherProps } = props

  const [buttonDown, setButtonDown] = useState(false)

  const onMouseDown = useCallback(() => {
    setButtonDown(true)
  }, [])

  const onMouseUp = useCallback(() => {
    setButtonDown(false)
  }, [])

  return (
    <motion.button
      className={
        'color-white bg-[rgba(127, 138, 241, 0.30)] relative flex h-[30px] items-center rounded-full border border-white px-3 py-0.5 text-base text-white shadow-button ' +
        className
      }
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      animate={{
        filter: 'blur(0.8px)',
        boxShadow: '0px 4px 12px rgba(127, 138, 241, 0.0)',
      }}
      whileHover={{
        filter: 'blur(0px)',
        scale: buttonDown ? 0.98 : 1.04,
        transition: { duration: 0.2 },
        boxShadow: '0px 4px 12px rgba(127, 138, 241, 0.25)',
      }}
      {...otherProps}
    >
      <div className="z-10 mt-[1px]">{title}</div>
    </motion.button>
  )
}

export default TextButton
