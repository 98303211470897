export default {
  content: [`./src/pages/**/*.{js,jsx,ts,tsx}`, `./src/components/**/*.{js,jsx,ts,tsx}`, `./src/containers/**/*.{js,jsx,ts,tsx}`],
  theme: {
    colors: {
      transparent: 'transparent',
      current: 'currentColor',
      white: '#ffffff',
      purple: '#3f3cbb',
      midnight: '#121063',
      metal: '#565584',
      tahiti: '#3ab7bf',
      silver: '#ecebff',
      'bubble-gum': '#ff77e9',
      bermuda: '#7F8AF1',
      black: '#000000',
      orange: '#FF8450',
      'dark-gray': '#1E1E1E',
      'off-white': '#E1E1E1',
    },
    extend: {
      blur: {
        xxs: '0.5px',
        xs: '2px',
      },
      boxShadow: {
        button: '0px 4px 12px rgba(127, 138, 241, 0.50)',
        blogcard: '0px 3px 5px rgba(255, 255, 255, 0.03)',
        'header-divider': '#FF8450 0px 0px 10px 2px',
      },
      gridTemplateColumns: {
        blog: 'repeat(auto-fill, minmax(250px, 1fr))',
      },
      height: {
        screen: ['100vh /* fallback for Opera, IE and etc. */', '100svh'],
      },
    },
  },
  plugins: [],
}
