import React from 'react'

const Footer = () => {
  return (
    <footer>
      {/* <p>Copyright © 2023 Thrum. All rights reserved.</p> */}
      {/* <div /> */}
    </footer>
  )
}

export default Footer
