import React, { useEffect } from 'react'
import TextButton from './TextButton'
import { motion, useMotionValue } from 'framer-motion'

import { useIsOnBlog } from '../utils/location'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { resolveTheme } from '../styles'
import { getSrc } from 'gatsby-plugin-image'

const Header = () => {
  const isOnBlog = useIsOnBlog()

  // Query to get twitter + github icons
  const socialIcons = useStaticQuery(graphql`
    query {
      twitter: file(name: { eq: "twitter" }) {
        ...FixedImage
      }
      github: file(name: { eq: "github" }) {
        ...FixedImage
      }
    }
  `)

  // Animate height depending on whether on blog or not
  const height = useMotionValue(0)

  useEffect(() => {
    height.set(isOnBlog ? 64 : 120)
  }, [isOnBlog])

  return (
    <motion.header
      className={`fixed inset-x-0 top-0 z-10 flex h-32 w-full items-center ${isOnBlog ? 'bg-black' : 'bg-transparent'}`}
      animate={{
        height: isOnBlog ? 64 : 120,
      }}
    >
      <motion.div className="bg-[rgba(0, 0, 0, 1.0)] flex flex-1 items-center justify-between rounded-full px-8 py-6">
        <Link to="/">
          <motion.div
            className="text-shadow-bermuda blur-xs text-4xl text-white md:-mr-[0.25em] md:px-10"
            whileHover={{
              filter: 'blur(0.5px)',
              textShadow: '0px 4px 12px rgba(127, 138, 241, 0.25)',
              scale: 1.02,
              color: resolveTheme()?.colors?.orange as string,
              transition: { duration: 0.2 },
            }}
          >
            T
            <motion.span
              className="inline-block"
              initial={{ transform: 'rotateX(0deg)' } as any}
              animate={{ transform: 'rotateX(360deg)' } as any}
              transition={{ duration: 0.5, repeat: Infinity, repeatDelay: 3.1 }}
            >
              H
            </motion.span>
            RUM
          </motion.div>
        </Link>

        <div className="flex md:pr-10">
          <a href="http://github.com/thrumdev" target="_blank" rel="noreferrer">
            <motion.img
              src={getSrc(socialIcons.github)}
              className="blur-xxs mx-3 h-[30px] w-[30px] object-contain"
              animate={{
                opacity: 0.8,
              }}
              initial={{
                opacity: 0,
              }}
              whileHover={{
                filter: 'blur(0px)',
                scale: 1.02,
                opacity: 1,
                transition: { duration: 0.2 },
              }}
            />
          </a>
          <a href="http://twitter.com/thrumdev" target="_blank" rel="noreferrer">
            <motion.div>
              <motion.img
                src={getSrc(socialIcons.twitter)}
                className="blur-xxs mx-3 h-[30px] w-[30px] object-contain"
                animate={{
                  opacity: 0.8,
                }}
                initial={{
                  opacity: 0.0,
                }}
                whileHover={{
                  filter: 'blur(0px)',
                  scale: 1.02,
                  transition: { duration: 0.2 },
                }}
              />
            </motion.div>
          </a>
          <Link to="/blog">
            <TextButton title="BLOG" />
          </Link>
        </div>

        <motion.div
          className={`shadow-header-divider absolute bottom-0 left-0 right-0 h-[1px] bg-orange ${isOnBlog ? 'opacity-100' : 'opacity-0'}`}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: isOnBlog ? 1 : 0,
          }}
        />
      </motion.div>
    </motion.header>
  )
}

export default Header
