import React, { useEffect, useState } from 'react'
import '../styles/layout.css'
import BgVideo from './BgVideo'
import { AnimatePresence, useTransform } from 'framer-motion'
import useTimeout from '../hooks/timeout.hook'
import { useIsOnBlog } from '../utils/location'
import { useSmoothScroll } from '../utils/scroll'
import useIsMobile from '../hooks/isMobile.hook'

interface Props {
  onDoneLoading: () => void
}

enum Progression {
  ActivityIndicator = 'ActivityIndicator',
  Glitch = 'Glitch',
  Resolution = 'Resolution',
  Finished = 'Finished',
}

const getVideoUrls = (url: string) => ({
  mp4: `${url}.mp4`,
  webm: `${url}.webm`,
})

const Background: React.FC<Props> = (props: Props) => {
  const { onDoneLoading } = props

  const isOnBlog = useIsOnBlog()

  const { scrollYProgress } = useSmoothScroll()

  // Transform scrollYProgress, transforming to an opacity value that goes to 0 at 0.1
  const opacity = useTransform(scrollYProgress, [0, 0.05, 0.1, 0.99, 1], [0.1, 0.1, 0.1, 0.1, 1])

  const isMobile = useIsMobile()

  const [progression, setProgression] = useState<Progression>(isOnBlog ? Progression.Finished : Progression.ActivityIndicator)

  useTimeout(() => {
    if (progression !== Progression.Finished) {
      setProgression(Progression.Glitch)
    }
  }, 2000)

  useTimeout(() => {
    if (progression !== Progression.Finished) {
      setProgression(Progression.Resolution)
    }
  }, 3000)

  useTimeout(() => {
    if (progression !== Progression.Finished) {
      setProgression(Progression.Finished)
    }
  }, 3000)

  // Call onDoneLoading when finished
  useEffect(() => {
    if (progression === Progression.Finished) {
      onDoneLoading()
    }
  }, [progression])

  const glitch1 = getVideoUrls('https://thrum-app.b-cdn.net/Glitch1')
  const glitch2 = getVideoUrls('https://thrum-app.b-cdn.net/Glitch2')
  const glitch3 = getVideoUrls('https://thrum-app.b-cdn.net/Glitch3')
  const glitch6 = getVideoUrls('https://thrum-app.b-cdn.net/Glitch3%20(fcp1)')
  const noiseSrc = getVideoUrls('https://thrum-app.b-cdn.net/Noise')
  const thrum2Src = getVideoUrls('https://thrum-app.b-cdn.net/thrum2')

  return (
    <div className="absolute inset-0 bg-black" style={{ zIndex: -1 }}>
      <AnimatePresence>
        {progression === Progression.Finished && (
          <>
            {isOnBlog ? (
              <>
                <BgVideo
                  key="blog-bg"
                  className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
                  urls={glitch1}
                  animate={{
                    opacity: [1.0, 0.9, 0.8, 0.9, 1.0],
                  }}
                  transition={{
                    duration: 6.0,
                    repeat: Infinity,
                    repeatType: 'loop',
                  }}
                  initial={{
                    translateX: '-50%',
                    translateY: '-50%',
                  }}
                  autoPlay={false}
                />
                <div className="fixed inset-0 backdrop-blur-3xl" />
              </>
            ) : (
              <>
                <BgVideo
                  key="noise1"
                  className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
                  urls={noiseSrc}
                  style={{
                    opacity,
                    display: isMobile ? 'none' : 'block',
                  }}
                  transition={{
                    duration: 6.0,
                    repeat: Infinity,
                    repeatType: 'loop',
                  }}
                  initial={{
                    translateX: '-50%',
                    translateY: '-50%',
                  }}
                  playbackRate={0.1}
                />

                <BgVideo
                  key="bg-video-1"
                  className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-90"
                  urls={glitch3}
                  playbackRate={1}
                  style={{
                    opacity,
                    display: isMobile ? 'none' : 'block',
                  }}
                />
              </>
            )}
          </>
        )}
        {progression === Progression.Glitch && (
          <>
            <BgVideo
              key="glitch1"
              className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
              urls={glitch1}
              style={{
                display: isMobile ? 'none' : 'block',
              }}
              animate={{
                opacity: [0.4, 0.6, 0.4],
                scale: [1.05, 1.0, 1.05],
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: 'loop',
              }}
              initial={{
                scale: 0,
                translateX: '-50%',
                translateY: '-50%',
                opacity: 0,
              }}
              exit={{
                translateX: '-49%',
                translateY: '-51%',
                scale: 1.1,
                transition: { duration: 0.1 },
              }}
            />
            <BgVideo
              key="glitch2"
              className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
              urls={glitch2}
              style={{
                display: isMobile ? 'none' : 'block',
              }}
              animate={{
                opacity: [0.6, 0.4, 0.6],
                scale: [1.0, 1.05, 1.0],
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: 'loop',
              }}
              initial={{
                scale: 0,
                translateX: '-50%',
                translateY: '-50%',
                opacity: 0,
              }}
              exit={{
                translateX: '-51%',
                translateY: '-49%',
                scale: 1.1,
                transition: { duration: 0.3 },
              }}
            />
            <BgVideo
              key="thrum2"
              className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
              urls={thrum2Src}
              animate={{
                opacity: [0.7, 0.5, 0.7],
                scale: [1.0, 1.05, 1.0],
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: 'loop',
              }}
              initial={{
                scale: 0,
                translateX: '-50%',
                translateY: '-50%',
                opacity: 0,
              }}
              exit={{
                translateX: '-51%',
                translateY: '-49%',
                scale: 1.1,
                transition: { duration: 0.3 },
              }}
            />
          </>
        )}
        {progression === Progression.ActivityIndicator && (
          <>
            <BgVideo
              key="bg-video-1"
              style={{
                display: isMobile ? 'none' : 'block',
              }}
              className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover"
              urls={glitch3}
            />

            <BgVideo
              key="glitch6"
              className="fixed left-1/2 top-1/2 z-0 h-screen min-h-full w-screen min-w-full -translate-x-1/2 -translate-y-1/2 object-cover opacity-50"
              urls={glitch6}
              animate={{
                opacity: [0.4, 0.6, 0.4],
                scale: [1.05, 1.0, 1.05],
              }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: 'loop',
              }}
              initial={{
                scale: 0,
                translateX: '-50%',
                translateY: '-50%',
                opacity: 0,
              }}
              playbackRate={0.1}
            />
          </>
        )}
      </AnimatePresence>
    </div>
  )
}

export default Background
